/* Resize Validea Logo */
.resize-brand {
    width:175px; /* you can use % */
    height: auto;
}

/* Center the Product Modal Button */
.center-button {
    text-align: center !important;
    margin-left: 105px !important;
}

@media only screen and (max-width: 768px) {
    .center-button {
        margin-left: 0px !important;
    }
}

table {
    border-collapse: collapse;
    width: 100%;
  }
  
  th, td {
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even){background-color: #f2f2f2}
  
  th {
    background-color: #A0A9DA;
    color: white;
  }